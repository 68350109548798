<script>
import { mapState, mapActions } from 'vuex';
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: null,
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    'chartData': function(){
      this.chartdata = this.chartData;
      this.renderChart(this.chartdata, this.options);
    }
  },
}
</script>
